<template>

  <div class="schArtDetail">

    <Head></Head>
    <div class="info">

      <div class="back">

        <span @click="backSch">返回</span>

        <em>/</em>
        <span>二手信息详情</span>
      </div>
      
    <div class="bar">
        <ul>
          <li>{{ traInfo.pubdata }}</li>
          <!-- <li>来源：{{ traInfo.author }}</li> -->
          <li>来源：{{ traInfo.author }}  [ID:{{traInfo.aut_id}}]</li>
          <li>浏览:{{ traInfo.view }}</li>
        </ul>
      </div>
    
      <!-- 商品主体 -->

      <div class="main">
        <div class="photo">
          <div v-if="haveAvator()">
            <img  :src="traInfo.photo">
          </div>
          <!-- <div v-else>
            <el-loading class="el-loading"  :image-size="200" :text="加载中"></el-loading>
          </div> -->
          <!-- <img v-else src="../../../public/images/toux.jpg"> -->
          <!-- <el-loading class="el-loading" v-show="!haveAvator()" :image-size="200" :text="加载中"></el-loading> -->
          <!-- <el-empty class="el-empty" v-show="!isShow4" :image-size="200"></el-empty> -->

        </div>
        <div class="introduce">
          <div class="content">
            <div class="title">
              <h2>{{ traInfo.title }}</h2>
              <h4>{{ traInfo.content }}</h4>
              <!-- <div class="ql-snow">
                <div class="ql-editor">
                  <div class="content">
                    <div v-html="Info.content"></div>
                </div>
                </div>
            </div> -->
              <p>价格：
                <span>￥{{ traInfo.price }}</span>
              </p>
              <p>种类：
                {{ traInfo.types }}
              </p>
              <div class="tel">
                联系方式：
                <a>{{ traInfo.tel }}</a>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { getTradeDetail } from '../../API/Trade.js'
import head from '../head.vue'
export default {
  name: 'tradeDetail',
  props: ['id'],
  data() {
    return {
      types: ['无种类', '电子用品', '日常百货', '学习用品', '运动户外', '文化娱乐', '食品保健', '服饰鞋包'],
      traInfo: {}

    }
  },
  beforeRouteUpdate(to, from, next) {
    // 当路由参数变化时，强制重新加载数据
    if (to.params.traId !== from.params.traId) {
      this.getDetail(to.params.traId);
    }
    next();
  },
  methods: {
    getDetail() {
      // console.log(this.id)
      this.$nextTick(async () => {
        const { data: res } = await getTradeDetail(this.id)

        if (res.status === 0) {
          for (var index in this.types) {
            if (parseInt(index) === res.data.types) {
              res.data.types = this.types[index]
            }
          }

          this.traInfo = res.data
        } else {
          this.$message({
            type: 'error',
            message: res.message
          })
        }
      })
    },
    backSch() {
      this.$emit('showTraInfo', true)
      this.$router.go(-1)
    },
    haveAvator() {
      // console.log(this.userInfo.username.)
      // console.log(this.traInfo)
      if (this.traInfo.photo) {
        return true
      } else {
        return false
      }
    }
  },
  created() {
    this.getDetail()
  },

  activated() {
    this.getDetail()
    this.haveAvator()
  },
  components: {
    Head: head
  },
  watch: {
    id(cur, old) {
      // this.traInfo = null
      this.getDetail()
    },
    Immediate: true
  }
}
</script>

<style lang="less" scoped>
li {
  list-style: none;
}

a {
  text-decoration: none;
}

.info {
  width: 90%;
  margin: .5rem auto;

  .back {

    span {
      display: inline-block;
      text-align: left;
      height: 37px;
      line-height: 37px;
      font-size: 14px;
      cursor: pointer;
      color: #999aaa;
      padding-left: .1333rem;

    }

    span:hover {
      color: #ff3535
    }

    em {
      margin: .0833rem;
    }
  }
  .bar {
    width: 100%;
    border-top: 1px solid #dcdcdc;

    ul {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      padding-left: 0;

      li {
        font-size: 14px;
        padding: 10px 20px 0 0;
        color: #585858;
      }
    }
  }
  .main {
    width: 90%;
    margin: 50px auto;
    // display: flex;

    .photo {
      flex: 3;

      img {
        width: 100%;
        height: 100%;
        border: 1px dotted #8d8383;
      }
      .el-loading{
          background-color: #fff;
          padding: 8px 0;
      }
    }

    .introduce {
      flex: 7;
      margin-top:30px;
      .content {
        width: 100%;
        height: 100%;
        margin: 0 auto;

        .title {
          h2 {
            margin: 0;
            padding: 0 0 15px 0;
            font-size: 26px;
            border-bottom: 1px solid #999aaa;
          }

          h4 {

            margin: 10px 0;
            font-size: 16px;
            font-weight: 500;
          }

          p {
            margin: 16px 0;
            font-size: 16px;
            color: #8d8383;

            span {
              font-size: 34px;
              color: #df3033
            }
          }

          .tel {

            font-size: 16px;
            color: #8d8383;
            font-family: "microsoft yahei";

            a {
              cursor: pointer;
              background-color: #F09294;
              height: 46px;
              line-height: 46px;
              padding: 0 26px;
              display: inline-block;
              font-size: 18px;
              font-weight: 500;
              color: #fff;
            }
          }
        }
      }
    }
  }

}
</style>
